<template>
  <ModalFormGeneric :id="COMPNAME" :title="$t('lgin.t')" :btnText="$t('lgin.b')" :btnDisabled="!val" btnIcon="lgi"
      @submit="onSubmit"
      @reset="onReset"
      :ref="COMPNAME">
    <b-form-group :label="$t('lgin.f.emaill')" :label-for="FORMNAME + 'Email'" class="text-left">
      <b-form-input type="email" :id="FORMNAME + 'Email'" v-model="f.emailAddress" :state="valEmail" required />
      <b-form-invalid-feedback :state="valEmail">
        {{ $t('lgin.f.emailf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group :label="$t('lgin.f.pwl')" :label-for="FORMNAME + 'Pw'" class="text-left">
      <b-form-input type="password" @keydown.enter.native="onSubmit" :id="FORMNAME + 'Pw'" v-model="f.password" :state="valPw" class="mt-2" pattern=".{8,}" required />
      <b-form-invalid-feedback :state="valPw">
        {{ $t('lgin.f.pwf') }}
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-checkbox :id="FORMNAME + 'Rem'" v-model="f.remember" class="mt-2">
      {{ $t('lgin.f.rem') }}
    </b-form-checkbox>
    <small class="text"><b-link @click="onShow()">{{ $t('lgin.f.frgtpw') }}</b-link></small>
  </ModalFormGeneric>
</template>

<script>
import ModalFormGeneric from '@/components/ModalFormGeneric';
import { REST, baseLOGIN } from '@/components/RestCall';
import { loadLang } from '@/router';

export default {
  data() {
    return {
      COMPNAME: 'loginWindow',
      FORMNAME: 'loginInput',
      f: {
        emailAddress: '',
        password: '',
        remember: false,
        website: true
      },
    }
  },
  computed: {
    valEmail() {
      if (this.f.emailAddress === '') return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.emailAddress.toLowerCase());
    },
    valPw() {
      if (this.f.password === '') return null;
      return this.f.password.length >= 8 ? true : false;
    },
    val() {
      return (this.valEmail === true && this.valPw === true);
    }
  },
  methods: {
    onSubmit() {
      if (this.val) {
        let self = this;
        REST.post(baseLOGIN.concat(''), this.f)
          .then(async function(resp) {
            const data = resp.h['x-uuid'].split('.');
            let oLogin = {
              uuid: data[0],
              isUser: (data[1] == 'u'),
              isLoggedIn: true
            };

            // save in store
            await loadLang(self.$router.currentRoute, self.$store.state.settings.languageId, oLogin);
            self.$store.commit('login', oLogin);
            self.$router.push('/').catch(() => {}); // redirect to landing page, ignore error if already there

            // show for user
            self.$store.commit('showSuccess',resp.d.message);
          }).catch(e => {
            this.$store.commit('showWarn',e.message)
          });
        this.$refs[this.COMPNAME].hide();
        this.$emit('submit');
      }
    },
    onReset() {
      this.f.emailAddress = '';
      this.f.password = '';
      this.f.remember = false;
      this.$emit('reset');
    },
    onShow() {
      this.$bvModal.hide(this.COMPNAME);
      this.$emit('pwreset');
    },
  },
  components: {
    ModalFormGeneric,
  },
}
</script>
